import React, { useState } from "react"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"
import InputText from "./InputText"
import TextArea from "./TextArea"
import Button from "./Button"

const Container = styled.form`
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 3rem;
`

const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.3;
  color: #505050;
`

const ContactForm = ({ title, sent, setSent, formType }) => {
  const [details, setDetails] = useState({ formType: `${formType} inquiry` })
  function handleChange(event) {
    setDetails({ ...details, [event.target.name]: event.target.value })
  }

  async function handleSubmit(e) {
    e.preventDefault()

    if (e.target.disabled) {
      return
    }

    let { name, email, body } = details
    e.target.disabled = true

    const res = await fetch(
      "https://us-central1-lt3-sendgrid-mailer.cloudfunctions.net/sendEmail",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, body }),
      }
    )
    if (res.status === 200) {
      setSent({ ...sent, status: true })
      setDetails({})
    } else {
      setSent({
        ...sent,
        status: true,
        title: "Oops something went wrong",
        message: "Please try again",
      })
    }
  }
  return (
    <Container onSubmit={handleSubmit}>
      <Secondary>{title}</Secondary>
      <InputText
        id="name"
        placeholder="Name"
        type="text"
        name="name"
        margin="normal"
        value={details.name || ""}
        onChange={handleChange}
        required
      />
      <InputText
        id="email"
        placeholder="Email"
        type="email"
        name="email"
        margin="normal"
        value={details.email || ""}
        onChange={handleChange}
        required
      />

      <TextArea
        id="body"
        name="body"
        placeholder="Message"
        multiline
        rowsMax="4"
        margin="normal"
        value={details.body || ""}
        onChange={handleChange}
      />
      <Button color="primary" type="submit">
        Send
      </Button>
    </Container>
  )
}

export default ContactForm
