import React from "react"
import { ToastContextProvider } from "./src/contexts/ToastContext"
import { LocationContextProvider } from "./src/contexts/LocationContext"
import { EmailContextProvider } from "./src/contexts/EmailContext"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => {
  return (
    <EmailContextProvider>
      <LocationContextProvider>
        <ToastContextProvider>{element}</ToastContextProvider>
      </LocationContextProvider>
    </EmailContextProvider>
  )
}
