import React, { createContext, useState, useEffect } from "react"
import publicIp from "public-ip"
import { LOCATIONS } from "../constants"

export const LocationContext = createContext()

const cityCountyArea = {
  cairns: ["atherton", "cairns"],
  townsville: ["townsville"],
  emerald: ["emerald"],
  canberra: ["canberra"],
  gympie: ["gympie"],
  sunshineCoast: ["sunshineCoast"],
  brisbane: [
    "brisbane",
    "northLakes",
    "logan",
    "redland",
    "moretonBay",
    "ipswich",
  ],
  goldCoast: ["goldCoast"],
  sydney: ["sydney"],
  melbourne: ["melbourne"],
  perth: ["perth"],
}

let cityCountyAreasFlattened = [],
  dropdownLocationsCamelCase = []

export const LocationContextProvider = ({ children }) => {
  //Default location is Calgary
  const [loc, setLoc] = useState({
    city: "brisbane",
    area: "brisbane",
    province: "queensland",
    cityDropdown: "brisbane",
    serviced: true,
  })

  //If location is allowed on the device, get location
  useEffect(() => {
    let parsedLoc = JSON.parse(localStorage.getItem("loc"))

    if (parsedLoc) {
      setLoc(parsedLoc)
    } else {
      if (navigator.geolocation) {
        // both parameters are callback functions - first is upon success and the second is upon failure of getCurrentPosition
        navigator.geolocation.getCurrentPosition(setCity, getPositionFromIp)
      }
    }

    // Get 1D list of all cities/counties on mount
    cityCountyAreasFlattened = Object.values(cityCountyArea).reduce(
      (sum, val) => [...sum, ...val],
      []
    )
    dropdownLocationsCamelCase = Object.values(LOCATIONS)
      .reduce((sum, val) => [...sum, ...val], [])
      .map((location) => toCamelCase(location))
  }, [])

  useEffect(() => {
    localStorage.setItem("loc", JSON.stringify(loc))
  }, [loc])

  function handleSetLoc(city, province) {
    let area,
      serviced = false,
      matchedCity = city,
      cityDropdown = dropdownLocationsCamelCase.find((camCasLoc) =>
        camCasLoc.includes(city)
      )

    //If location is changed through dropdown, update area
    for (let key of Object.keys(cityCountyArea)) {
      let contextCityMatch = cityCountyArea[key].find((contextCity) =>
        city.includes(contextCity)
      )

      if (contextCityMatch) {
        area = key
        matchedCity = contextCityMatch
        serviced = true
        break
      }
    }

    setLoc({ city: matchedCity, province, area, cityDropdown, serviced })
  }

  async function setCity(position) {
    const res = await fetch(
      `https://us1.locationiq.com/v1/reverse.php?key=${process.env.GATSBY_LOCATIONIQ_API}&lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`
    )
    if (res.status === 200) {
      const json = await res.json()

      let city = "City and county undefined",
        jsonCity = json.address.city
          ? toCamelCase(json.address.city)
          : undefined,
        jsonCounty = json.address.county
          ? toCamelCase(json.address.county)
          : undefined

      // Because the locations can be either cities or counties, the following searches cities first then counties (i.e. search goes from more specific to less specific)
      if (cityCountyAreasFlattened.includes(jsonCity)) {
        city = jsonCity
      } else if (cityCountyAreasFlattened.includes(jsonCounty)) {
        city = jsonCounty
      }

      const province = json.address.state || "State undefined"

      handleSetLoc(city, toCamelCase(province))
    }
  }

  async function getPositionFromIp() {
    const ip = await publicIp.v4()
    const res = await fetch(`https://ipapi.co/${ip}/json`)
    if (res.ok) {
      const json = await res.json()
      setCity({
        coords: { latitude: json.latitude, longitude: json.longitude },
      })
    }
  }

  return (
    <LocationContext.Provider value={{ loc, handleSetLoc }}>
      {children}
    </LocationContext.Provider>
  )
}

function toCamelCase(string) {
  string = string
    .trim()
    .toLowerCase()
    .replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
      return match.charAt(match.length - 1).toUpperCase()
    })
  return string.charAt(0).toLowerCase() + string.substring(1)
}
