// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-page-js": () => import("./../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-contact-post-js": () => import("./../../src/templates/contact-post.js" /* webpackChunkName: "component---src-templates-contact-post-js" */),
  "component---src-templates-faq-page-js": () => import("./../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-fundraising-page-js": () => import("./../../src/templates/fundraising-page.js" /* webpackChunkName: "component---src-templates-fundraising-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-locations-page-js": () => import("./../../src/templates/locations-page.js" /* webpackChunkName: "component---src-templates-locations-page-js" */),
  "component---src-templates-locations-post-js": () => import("./../../src/templates/locations-post.js" /* webpackChunkName: "component---src-templates-locations-post-js" */),
  "component---src-templates-media-page-js": () => import("./../../src/templates/media-page.js" /* webpackChunkName: "component---src-templates-media-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-promo-post-js": () => import("./../../src/templates/promo-post.js" /* webpackChunkName: "component---src-templates-promo-post-js" */),
  "component---src-templates-recycling-page-js": () => import("./../../src/templates/recycling-page.js" /* webpackChunkName: "component---src-templates-recycling-page-js" */),
  "component---src-templates-recycling-post-js": () => import("./../../src/templates/recycling-post.js" /* webpackChunkName: "component---src-templates-recycling-post-js" */),
  "component---src-templates-services-page-js": () => import("./../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-services-post-js": () => import("./../../src/templates/services-post.js" /* webpackChunkName: "component---src-templates-services-post-js" */)
}

