import React from "react"
import styled from "@emotion/styled"

const Btn = styled.button`
  display: block;
  margin: 0 auto;
  font-size: 1.9rem;
  padding: 1rem;
  min-width: 150px;
  box-shadow: ${props =>
    props.variant
      ? "none"
      : "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"};
  background: ${props => (props.variant ? "transparent" : "#8cc949")};
  color: ${props => (props.variant ? "#8cc949" : "#fafafa")};
  border: ${props => (props.variant ? "solid 1px #8cc949" : "none")};
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 5px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    background: ${props => (props.variant ? "#f0f8e7" : "#77b536")};
    box-shadow: ${props =>
      props.variant
        ? "none"
        : "0px 5px 10px -2px rgba(0,0,0,0.2), 0px 4px 10px 0px rgba(0,0,0,0.14), 0px 2px 5px 0px rgba(0,0,0,0.12)"};
    outline: none;
  }
  &:active {
    outline: none;
  }
`

const Button = ({ children, ...props }) => {
  return <Btn {...props}>{children}</Btn>
}

export default Button
