import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import Card from "../components/Card"
import ContactForm from "../components/ContactForm"
import PostSubmit from "../components/PostSubmit"

const Container = styled.div`
  position: fixed;
  background-color: rgba(50, 50, 50, 0.5);
  height: 100vh;
  width: 100vw;
  min-height: 30rem;
  z-index: 101;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    max-width: 500px;
    position: relative;
    padding: 0;
    margin: 3rem;
  }
`

const CloseButton = styled.span`
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: block;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 2rem;
    background: #404040;
    top: 50%;
    left: 50%;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

const EmailController = ({ showEmail, setShowEmail }) => {
  const [sent, setSent] = useState({
    status: false,
    title: "Thanks for reaching out",
    message: "We'll get back to you as soon as we can!",
  })
  useEffect(() => {
    //If the modal is visible, prevents scrolling of the anything else other than the modal
    showEmail
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset")

    //Creating an event listener which will call the function escFunction anytime a key is pressed.
    document.addEventListener("keydown", e =>
      e.keyCode === 27 ? setShowEmail(false) : null
    )

    //Removing the event listener attached to keypresses
    return document.removeEventListener("keydown", e =>
      e.keyCode === 27 ? setShowEmail(false) : null
    )
  })
  if (!showEmail) return null
  return (
    <Container>
      <Card>
        <CloseButton onClick={() => setShowEmail(false)} />
        {sent.status ? (
          <PostSubmit title={sent.title}>{sent.message}</PostSubmit>
        ) : (
          <ContactForm
            setSent={setSent}
            sent={sent}
            title="Send us an email"
            formType="General"
          />
        )}
      </Card>
    </Container>
  )
}

export default EmailController
