import React from "react"
import styled from "@emotion/styled"

const Input = styled.input`
  display: block;
  background-color: #efefef;
  border: none;
  font-size: 16px;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 12px 15px;
  width: 100%;
  border-radius: 10px;
  color: #505050;
  margin: 10px auto;
  &:focus,
  &:hover {
    outline: none;
    background-color: #dfdfdf;
  }
`

const InputText = ({ ...props }) => {
  return <Input {...props} />
}

export default InputText
