import React, { createContext, useState } from "react"

export const ToastContext = createContext()

export const ToastContextProvider = ({ children }) => {
  const [cta, setCta] = useState(true)

  return (
    <ToastContext.Provider value={{ cta, setCta }}>
      {children}
    </ToastContext.Provider>
  )
}
