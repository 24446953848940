export const LOCATIONS = {
  queensland: [
    "Gold Coast",
    "Brisbane",
    "North Lakes",
    "Sunshine Coast",
    "Gympie",
    "Emerald",
    "Townsville",
    "Atherton",
    "Cairns",
    "Logan",
    "Redland",
    "Moreton Bay",
    "Ipswich",
  ],
  australianCapitalTerritory: ["Canberra"],
  newSouthWales: [
    "Sydney Eastern Suburbs & Inner West",
    "Sydney Northshore & Northern Beaches",
    "Sydney South, Southwest & West",
    "Sydney Northern Suburbs & Northwest",
  ],
  westernAustralia: ["Perth"],
  victoria: ["Melbourne"],
}

export const PROVINCES = {
  queensland: "QLD",
  australianCapitalTerritory: "ACT",
  newSouthWales: "NSW",
  westernAustralia: "WA",
  victoria: "VIC",
}
