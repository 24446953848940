import React, { createContext, useState } from "react"
import EmailController from "../controllers/EmailController"

export const EmailContext = createContext()

export const EmailContextProvider = ({ children }) => {
  const [showEmail, setShowEmail] = useState(false)

  return (
    <EmailContext.Provider value={{ showEmail, setShowEmail }}>
      {children}
      <EmailController showEmail={showEmail} setShowEmail={setShowEmail} />
    </EmailContext.Provider>
  )
}
